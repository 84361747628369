import React, { useContext, useEffect, useState, useRef } from 'react';

import { Cookie } from '../services';
import { UserContext } from '../context/UserContext';
import * as Utils from '../utils';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { RootState } from '../redux';
import { Slide, toast, ToastPosition, ToastTransitionProps } from 'react-toastify';
import {
  getIsExpiredLogoutCase,
  getParsedTimestampsArray,
  localStorageLogoutErrorsArrayBuilder,
  allSessionsInitTimestampsSetter,
  userSessionCookiesHandler,
} from './utility/useRefreshSessionsHookUtilities';
import { logoutConfigSliceActions } from '../redux/gtw-logout-config-slice';

const TIME_BEFORE_GLOBAL_SESSION_EXPIRES = 15 * 60 * 1000;
const TIME_BEFORE_SESSION_EXTEND = 10 * 60 * 1000;
const POSTPONED_TIME_BEFORE_SESSION_EXTEND = 90 * 1000;
const TIME_BEFORE_HARD_LOGOUT = 15 * 1000;

interface IToastOptions {
  position: ToastPosition | undefined;
  autoClose: number | false | undefined;
  hideProgressBar: boolean;
  closeOnClick: boolean;
  pauseOnHover: boolean;
  draggable: boolean;
  progress: undefined;
  theme: string;
  transition: ({
    children,
    position,
    preventExitTransition,
    done,
    nodeRef,
    isIn,
    playToast,
  }: ToastTransitionProps) => React.JSX.Element;
  className: string;
}

const toastOptions: IToastOptions = {
  position: 'top-center',
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
  transition: Slide,
  className: 'logoutBanner',
};

const bannerJSXBuilder = () => {
  const globalPortalSessionExpiresAt = Cookie.getGlobalPortalSessionExpiresAt();
  const expirationTimeString = new Date(Number(globalPortalSessionExpiresAt)).toLocaleTimeString();

  if (globalPortalSessionExpiresAt && globalPortalSessionExpiresAt > String(Date.now())) {
    return (
      <div>
        According to your organisation`s security policy, you`ll be logged out at <b>{expirationTimeString}</b>.
        <br />
        Please save your progress to avoid any interruption in your workflow.
      </div>
    );
  }
  return null;
};

export const useRefreshSessions = () => {
  const userContext = useContext(UserContext);
  const dispatch = useAppDispatch();
  const logoutConfig = useAppSelector((state: RootState) => state.gtwLogoutConfigSliceData.logoutConfig);

  const [timeBeforeSessionExtend, setTimeBeforeSessionExtend] = useState(TIME_BEFORE_SESSION_EXTEND);
  const [refreshSessionPopupOpened, setRefreshSessionPopupOpened] = useState(false);

  const interval = useRef<Record<string, unknown>>({});

  const handleCloseRefreshModal = (deactivateInterval = false) => {
    if (deactivateInterval) {
      clearInterval(interval.current.timeout as NodeJS.Timeout);
    }
    setRefreshSessionPopupOpened(false);
  };

  const postponeSessionRefresh = () => {
    const refreshPostponeAvailable = sessionStorage.getItem('isRefreshSessionPostponeAvailable');
    if (refreshPostponeAvailable) {
      setTimeBeforeSessionExtend(POSTPONED_TIME_BEFORE_SESSION_EXTEND);
      sessionStorage.removeItem('isRefreshSessionPostponeAvailable');
    } else {
      setTimeBeforeSessionExtend(0);
    }
  };

  const onKeepLogin = async (extendOnlyOktaSession: boolean, ignoreOktaRefreshFailures: boolean) => {
    setRefreshSessionPopupOpened(false);

    if (!extendOnlyOktaSession) {
      userSessionCookiesHandler(true);
      setTimeBeforeSessionExtend(TIME_BEFORE_SESSION_EXTEND);
    }

    const extendOktaSessionRes = await userContext.extendOktaSession();

    if (!extendOktaSessionRes.success && !ignoreOktaRefreshFailures && !logoutConfig.isActive) {
      localStorageLogoutErrorsArrayBuilder('refresh Okta error');
      dispatch(logoutConfigSliceActions.setLogoutConfig({ isActive: true, customText: 'refresh Okta error' }));
    }
  };

  useEffect(() => {
    if (window.location.pathname === '/auth/login') {
      clearInterval(interval.current.TenSecondsCheck as NodeJS.Timeout);

      setRefreshSessionPopupOpened(false);
      dispatch(logoutConfigSliceActions.setLogoutConfig({ isActive: false }));
    } else {
      if (userContext.isOktaSessionMeSuccessful === null) {
        return;
      }

      const isTargetedSessionStatusRequestFailed = localStorage.getItem('targetedSessionStatusRequest');
      if (!userContext.isOktaSessionMeSuccessful && !isTargetedSessionStatusRequestFailed) {
        localStorageLogoutErrorsArrayBuilder('initial session status Okta error');
        dispatch(
          logoutConfigSliceActions.setLogoutConfig({ isActive: true, customText: 'initial session status Okta error' }),
        );
        return;
      }

      const areOktaRefreshTimestampsSet = Boolean(localStorage.getItem('oktaRefreshTimestampsArray'));
      const areSessionExtendTimestampsSet = Boolean(localStorage.getItem('sessionExtendTimestampsArray'));

      if (areOktaRefreshTimestampsSet && areSessionExtendTimestampsSet) {
        return;
      } else {
        const customUserSessionTimeout = userContext.user.timeout_to_extend_session || 4;

        allSessionsInitTimestampsSetter(
          areOktaRefreshTimestampsSet,
          areSessionExtendTimestampsSet,
          customUserSessionTimeout,
        );
        userSessionCookiesHandler();
      }
    }
  }, [window.location.pathname, userContext.isOktaSessionMeSuccessful]);

  useEffect(() => {
    interval.current.TenSecondsCheck = setInterval(() => {
      const timeNow = new Date().getTime();
      const oktaSessionExpiresAt = Cookie.getOktaSessionExpiresAt();
      const globalPortalSessionExpiresAt = Cookie.getGlobalPortalSessionExpiresAt();

      const isLoginPageCase = window.location.pathname.startsWith('/auth/login');

      if (isLoginPageCase) {
        clearInterval(interval.current.timeout as NodeJS.Timeout);
        return;
      }
      // if refreshSessionPopup is opened, session extend as well as session logout logic in carried therein
      if (refreshSessionPopupOpened) {
        return;
      }

      const isExpiredLogoutCase = getIsExpiredLogoutCase();

      if (isExpiredLogoutCase) {
        Utils.navigateToLogin();
        return;
      }

      const isTimeToHardLogoutCase = Number(globalPortalSessionExpiresAt) - timeNow < TIME_BEFORE_HARD_LOGOUT;

      if (isTimeToHardLogoutCase) {
        setRefreshSessionPopupOpened(false);
        localStorageLogoutErrorsArrayBuilder('user session was terminated at due time');
        dispatch(
          logoutConfigSliceActions.setLogoutConfig({
            isActive: true,
            customText: 'dully expiration of your session`s timespan.',
          }),
        );
        return;
      }

      const isTimeToShowGlobalExpireBannerCase =
        Number(globalPortalSessionExpiresAt) - timeNow < TIME_BEFORE_GLOBAL_SESSION_EXPIRES;

      const wasGlobalExpireBannerDisplayed = sessionStorage.getItem('wasGlobalExpireBannerDisplayed');

      if (isTimeToShowGlobalExpireBannerCase && !wasGlobalExpireBannerDisplayed) {
        sessionStorage.setItem('wasGlobalExpireBannerDisplayed', 'true');
        toast.info(bannerJSXBuilder(), toastOptions);
      }

      // at this point of time we are sure that:
      // we are not on the login page, none of the sessions is expired, it is not the time to show final banner and it is not the time for hard logout yet
      // thus we just need to track necesity to refresh okta & gtw sessions
      const sessionExtendTimestampsArray = localStorage.getItem('sessionExtendTimestampsArray');
      const parsedSessionExtendTimestampsArray = getParsedTimestampsArray(sessionExtendTimestampsArray);

      if (parsedSessionExtendTimestampsArray.length) {
        const isGatewaySessionAboutToExpire =
          Number(parsedSessionExtendTimestampsArray[0]) - timeNow < timeBeforeSessionExtend;

        const isRefreshSessionPostponeAvailable =
          Number(parsedSessionExtendTimestampsArray[0]) - timeNow > POSTPONED_TIME_BEFORE_SESSION_EXTEND;

        if (isGatewaySessionAboutToExpire && !refreshSessionPopupOpened) {
          setRefreshSessionPopupOpened(true);
          if (isRefreshSessionPostponeAvailable) {
            sessionStorage.setItem('isRefreshSessionPostponeAvailable', 'true');
          }
        }
      }

      const oktaRefreshTimestampsArray = localStorage.getItem('oktaRefreshTimestampsArray');
      let parsedOktaRefreshTimestampsArray = getParsedTimestampsArray(oktaRefreshTimestampsArray);

      if (parsedOktaRefreshTimestampsArray.length) {
        if (Number(oktaSessionExpiresAt) >= Number(globalPortalSessionExpiresAt)) {
          parsedOktaRefreshTimestampsArray = [];
          localStorage.setItem('oktaRefreshTimestampsArray', JSON.stringify(parsedOktaRefreshTimestampsArray));
        } else {
          const isTimeToRefreshOkta = Number(parsedOktaRefreshTimestampsArray[0]) - timeNow < 0;

          if (isTimeToRefreshOkta) {
            const shouldIgnoreFailures =
              Boolean(parsedOktaRefreshTimestampsArray[1]) &&
              Number(oktaSessionExpiresAt) - Number(parsedOktaRefreshTimestampsArray[1]) > 0;

            parsedOktaRefreshTimestampsArray.shift();

            localStorage.setItem('oktaRefreshTimestampsArray', JSON.stringify(parsedOktaRefreshTimestampsArray));

            onKeepLogin(true, shouldIgnoreFailures);
          }
        }
      }
    }, 10 * 1000);

    return () => {
      clearInterval(interval.current.TenSecondsCheck as NodeJS.Timeout);
    };
  }, [refreshSessionPopupOpened, timeBeforeSessionExtend]);

  return {
    refreshSessionPopupOpened,
    onKeepLogin,
    postponeSessionRefresh,
    handleCloseRefreshModal,
  };
};
