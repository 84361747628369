import { configureStore } from '@reduxjs/toolkit';
import currentJobSlice from './reli-current-job-slice';
import auxiliaryFlagsSlice from './reli-auxiliary-flags-slice';
import jobTableEditingSlice from './reli-job-table-editing-slice';
import pdfviewerSlice from './reli-pdf-viewer-slice';
import menuItemsSlice from './gtw-menu-items-slice';
import logoutConfigSlice from './gtw-logout-config-slice';
import gatewayRequestsSlice from './gtw-requests-slice';

const store = configureStore({
  reducer: {
    reliCurJobSliceData: currentJobSlice,
    reliAuxFlagsSliceData: auxiliaryFlagsSlice,
    reliTableEditingSliceData: jobTableEditingSlice,
    relipdfviewerSlice: pdfviewerSlice,
    gtwMenuItemsSliceData: menuItemsSlice,
    gtwLogoutConfigSliceData: logoutConfigSlice,
    gtwRequestsSlice: gatewayRequestsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['reliAuxFlagsSliceData.modalDialogSetup'],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
