import { createSlice } from '@reduxjs/toolkit';

interface IInitialState {
  logoutConfig: {
    isActive: boolean;
    customText: string;
  };
}

const initialState: IInitialState = {
  logoutConfig: { isActive: false, customText: '' },
};

export const logoutConfigSlice = createSlice({
  name: 'logout-config-slice',
  initialState,
  reducers: {
    setLogoutConfig(state, action) {
      state.logoutConfig = action.payload;
    },
  },
});

export const logoutConfigSliceActions = logoutConfigSlice.actions;

export default logoutConfigSlice.reducer;
