import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, SxProps, Theme } from '@mui/material';

import {
  convertSecondsToText,
  getSessionExpireCountDownTime,
  localStorageLogoutErrorsArrayBuilder,
} from './utility/useRefreshSessionsHookUtilities';

import ModalContainer from '../components/ReusableComponents/ModalContainer';
import { StyledCardContent } from '../components/Common/ModalDialog/styles';
import { getColors } from '../utils/colors';
import { logoutConfigSliceActions } from '../redux/gtw-logout-config-slice';
import { useAppDispatch } from '../redux/hooks';

const refreshSessionPopupStyles: Record<string, SxProps<Theme> | undefined> = {
  modalHeading: (theme) => ({
    display: 'flex',
    color: '#0A354C !important',
    fontSize: '24px',
    lineHeight: '24px',
    fontFamily: 'Neuzeit-Grotesk, sans-serif',
    margin: '0 0 20px 0',
    fontWeight: 'bold',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '20px',
      fontSize: '20px',
      margin: '10px 0 10px 0',
    },
  }),
  contentWrapper: (theme) => ({
    width: '450px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  }),
  warningText: (theme) => ({
    fontFamily: 'Neuzeit-Grotesk, sans-serif',
    margin: '0 0 20px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 20px 0',
      fontSize: '15px',
    },
  }),
};

interface RefreshSessionPopupProps {
  refreshSessionPopupOpened: boolean;
  handleKeepLogin: () => void;
  handleCloseRefreshModal: (deactivateInterval?: boolean) => void;
  postponeSessionRefresh: () => void;
}

export const RefreshSessionPopup: React.FC<RefreshSessionPopupProps> = ({
  refreshSessionPopupOpened,
  handleKeepLogin,
  handleCloseRefreshModal,
  postponeSessionRefresh,
}) => {
  const dispatch = useAppDispatch();
  const [countDownTimer, setCountDownTimer] = useState(getSessionExpireCountDownTime());

  useEffect(() => {
    if (window.location.pathname === '/auth/login') {
      return;
    }

    const updateCountDown = () => {
      const timeLeft = getSessionExpireCountDownTime();

      if (timeLeft <= 0) {
        localStorageLogoutErrorsArrayBuilder('Refresh Session Popup was ignored');
        handleCloseRefreshModal(true);
        dispatch(
          logoutConfigSliceActions.setLogoutConfig({
            isActive: true,
            customText: 'dully expiration of your custom session timeout',
          }),
        );
        return;
      }

      setCountDownTimer(timeLeft);
    };

    updateCountDown();
    const intervalId = setInterval(updateCountDown, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const closeModalHandler = () => {
    postponeSessionRefresh();
    handleCloseRefreshModal();
  };

  return (
    <ModalContainer isOpen={refreshSessionPopupOpened} closeModal={closeModalHandler} showCloseIcon={false}>
      <StyledCardContent colors={getColors()} width="550px">
        <Typography sx={refreshSessionPopupStyles.modalHeading}>Session Expiration Warning</Typography>
        <Box sx={refreshSessionPopupStyles.contentWrapper}>
          <Typography sx={refreshSessionPopupStyles.warningText}>
            Your session will expire in <strong> {convertSecondsToText(countDownTimer)}</strong>. <br />
            Do you want to extend the session?
          </Typography>
          <Box className="buttons-container">
            <Button variant="outlined" onClick={handleKeepLogin} className="dialog-button" style={{ width: '40%' }}>
              Extend
            </Button>
            <Button
              variant="outlined"
              onClick={closeModalHandler}
              className="dialog-button red-button"
              style={{ width: '40%' }}
            >
              Ignore
            </Button>
          </Box>
        </Box>
      </StyledCardContent>
    </ModalContainer>
  );
};
