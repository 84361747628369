import axios from 'axios';
import _ from 'lodash';
import { Cookie } from '..';
import { Types } from '../../constants';
import { getFailedApiCallResponse } from '../utils';

export const getCalendarsForRoleId = async (
  roleId: string,
): Promise<{
  success: boolean;
  data?: Types.CalendarType[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get(`calendars/${roleId}`, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200) return { success: true, data: res.data };
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const createCalendar = async (
  data: Types.CalendarType,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post('calendars', data, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 201) return { success: true };
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const deleteCalendar = async (
  data: Types.CalendarType,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const id = data.id;
    delete data.id;
    const res = await axios.delete(`calendars/${id}`, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 204) return { success: true };
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const updateCalendar = async (
  id: string,
  data: Types.CalendarType,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    delete data.id;
    const res = await axios.put(`calendars/${id}`, data, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200) return { success: true };
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getUpcomingEvents = async (
  calendarIds: string[],
): Promise<{
  success: boolean;
  data?: Partial<Types.IUpcomingEventsData>[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post(
      `calendars/upcoming-events`,
      { calendarIds },
      {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      },
    );
    if (res.status === 200) {
      return { success: true, data: res.data?.events || [] };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};
