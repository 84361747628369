import { styled, alpha } from '@mui/material/styles';
import { CardContent } from '@mui/material';

import { Types } from '../../../../constants';

export const StyledCardContent = styled(CardContent)<Types.IParentDiv>`
  padding: 20px;
  outline: none;
  max-width: ${(props: any) => (props.width ? props.width : '350px')};

  .title {
    line-height: 28px;
    color: #0b354c;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    margin: 10px 0;
    text-transform: capitalize;
  }

  .main-text {
    line-height: 18px;
    color: #0b354c;
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    margin: 0 0 20px 0;
    & * {
      color: #0b354c;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
  }

  .dialog-button,
  .dialog-button-inverted {
    height: 45px;
    width: 100%;
    font-weight: bold;
    margin: 0;
    align-self: center;
    background-color: ${(props: any) => alpha(props.colors.primary, 0.9)};
    color: #fff;

    &:hover {
      border: 1px solid white;
      background-color: ${(props: any) => alpha(props.colors.primary, 0.9)};
    }

    &:disabled {
      color: #fff;
      background-color: ${(props) => alpha(props.colors.primary, 0.5)};
    }

    &:last-child {
      margin: 0;
    }
  }

  .dialog-button-inverted {
    background-color: #fff;
    color: ${(props) => alpha(props.colors.primary, 0.9)};
    border: 1px solid ${(props) => alpha(props.colors.primary, 0.9)};

    &:hover {
      border: 1px solid white;
      background-color: ${(props) => alpha(props.colors.primary, 0.9)};
      color: #fff;
    }

    &:disabled {
      background-color: #fff;
      color: ${(props) => alpha(props.colors.primary, 0.9)};
    }
  }

  .add-level {
    height: 1.8rem;
    width: 7.5rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    align-self: flex-end;
    background: none;
    color: #000;
    border-color: ${(props) => props.colors.primary};

    &:hover {
      color: #fff;
    }
  }

  .close-icon {
    height: 20px;
    width: 20px;
  }

  .red-button {
    background-color: ${(props: any) => alpha(props.colors.secondary, 0.9)};

    &:hover {
      background-color: ${(props: any) => alpha(props.colors.secondary, 0.9)};
    }
  }

  .MuiTableContainer-root {
    overflow: hidden;
  }

  thead {
    th {
      padding: 0;
      font-weight: bold;
    }
  }

  tbody {
    th {
      border: none;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }

  .sort-description {
    width: 3.5rem;
    margin-right: 10px;
    font-size: 15px;
  }

  .logic-select-field {
    width: 40px;
    padding: 10px;
  }

  .logic-select-field {
    width: 40px;
    padding: 10px;
  }

  .column-select-field {
    height: 40px;
    width: 150px;

    @media screen and (max-width: 780px) {
      max-width: 100px;
    }
  }

  .order-select-field {
    height: 40px;
    min-width: 70px;

    @media screen and (max-width: 780px) {
      max-width: 80px;
    }
  }

  .filter-value {
    margin-left: 10px;
    input {
      padding: 10px;
      min-width: 150px;
    }
  }

  .select-error {
    border: 1px solid red;
  }

  .input-error {
    input {
      border: 1px solid red;
    }
  }

  .column-error-description {
    position: absolute;
    color: red;
    font-size: 10px;
    margin-left: 4rem;
  }

  .order-error-description {
    position: absolute;
    color: red;
    font-size: 10px;
  }

  .value-error-description {
    position: absolute;
    color: red;
    font-size: 10px;
    right: 1.5rem;
  }

  .module-container {
    min-height: 65px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .module-content {
      font-family:
        Nunito Sans,
        sans-serif;
      .module-title {
        font-weight: bold;
        color: #222;
        font-size: 14px;
        margin-right: 2rem;
      }
    }

    .docs-select-dropdown {
      height: 2rem;
      width: 3rem;
      border-radius: 4px;
      background: #fff;
      margin-right: 15px;
    }
  }

  .bug-modal-text-area {
    border-radius: 0;
    width: 100%;
    input {
      font-size: 14px;
      padding: 10px;
    }
  }

  .field-error-description {
    color: red;
    font-size: 10px;
  }

  .section-label {
    font-weight: bold;
    color: #0b354c;
    margin: 0;
  }
`;
