import { customUserSessionTimeoutEnum, localStorageLogoutError } from '../../../constants/Types';
import { Cookie } from '../../../services';

const HOUR_MILISEC = 60 * 60 * 1000;
const TWENTY_FIVE_MINS_IN_MS = 25 * 60 * 1000;
const ONE_HOUR_FIFTY_NINE_MINS_IN_MS = 119 * 60 * 1000;

export const getIsExpiredLogoutCase = () => {
  const timeNow = new Date().getTime();

  const oktaSessionExpiresAt = Cookie.getOktaSessionExpiresAt();
  const gatewaySessionExpiresAt = Cookie.getGatewaySessionExpiresAt();
  const globalPortalSessionExpiresAt = Cookie.getGlobalPortalSessionExpiresAt();

  const oktaSessionIsExpired = !oktaSessionExpiresAt || Number(oktaSessionExpiresAt) <= timeNow;
  const gtwSessionIsExpired = !gatewaySessionExpiresAt || Number(gatewaySessionExpiresAt) <= timeNow;
  const globalPortalSessionIsExpired = !globalPortalSessionExpiresAt || Number(globalPortalSessionExpiresAt) <= timeNow;

  const isExpiredLogoutCase = oktaSessionIsExpired || gtwSessionIsExpired || globalPortalSessionIsExpired;

  const expiredCaseObject = {
    oktaSessionIsExpired: oktaSessionIsExpired ? 'oktaSessionIsExpired' : '',
    gtwSessionIsExpired: gtwSessionIsExpired ? 'gtwSessionIsExpired' : '',
    globalPortalSessionIsExpired: globalPortalSessionIsExpired ? 'globalPortalSessionIsExpired' : '',
  };

  if (isExpiredLogoutCase) {
    const errorLine = `${expiredCaseObject.globalPortalSessionIsExpired}_${expiredCaseObject.gtwSessionIsExpired}_${expiredCaseObject.oktaSessionIsExpired}`;
    localStorageLogoutErrorsArrayBuilder(`getIsExpiredLogoutCase_${errorLine}`);
  }

  return isExpiredLogoutCase;
};

export const portalSessionCookiesSetter = (createdAt: string, expiresAt: string) => {
  const createdAtInMs = String(new Date(createdAt).getTime());
  Cookie.setOktaSessionCreatedAt(createdAtInMs);

  const expiresAtInMs = String(new Date(expiresAt).getTime());
  Cookie.setOktaSessionExpiresAt(expiresAtInMs);

  const isGlobalPortalSessionExpiresAtSet = Cookie.getGlobalPortalSessionExpiresAt();

  if (!isGlobalPortalSessionExpiresAtSet) {
    const fourHoursInMs = 4 * 60 * 60 * 1000;

    const globalPortalSessionExpiresAt = Number(createdAtInMs) + fourHoursInMs;
    Cookie.setGlobalPortalSessionExpiresAt(String(globalPortalSessionExpiresAt));
  }
};

export const localStorageLogoutErrorsArrayBuilder = (reason: string) => {
  const localStorageLogoutErrorsArray = localStorage.getItem(localStorageLogoutError.logoutErrorsArray);

  let parsedLocalStorageLogoutErrorsArray = [];

  try {
    parsedLocalStorageLogoutErrorsArray = localStorageLogoutErrorsArray
      ? JSON.parse(localStorageLogoutErrorsArray)
      : [];
  } catch (error) {}

  if (Array.isArray(parsedLocalStorageLogoutErrorsArray) && parsedLocalStorageLogoutErrorsArray.length > 25) {
    parsedLocalStorageLogoutErrorsArray = parsedLocalStorageLogoutErrorsArray.slice(0, 25);
  }

  const reportTimestamp = String(Date.now());
  // Cookies values are additionally stringified to cover undefined case
  const userTimeoutLengthCookie = String(Cookie.getUserTimeoutLength());
  const oktaSessionCreatedAtCookie = String(Cookie.getOktaSessionCreatedAt());
  const oktaSessionExpiresAtCookie = String(Cookie.getOktaSessionExpiresAt());
  const gatewaySessionExpiresAtCookie = String(Cookie.getGatewaySessionExpiresAt());
  const globalPortalSessionExpiresAtCookie = String(Cookie.getGlobalPortalSessionExpiresAt());

  parsedLocalStorageLogoutErrorsArray.unshift({
    reportTimestamp,
    reportReason: reason,
    userTimeoutLength: userTimeoutLengthCookie,
    oktaSessionCreatedAt: oktaSessionCreatedAtCookie,
    oktaSessionExpiresAt: oktaSessionExpiresAtCookie,
    gatewaySessionExpiresAt: gatewaySessionExpiresAtCookie,
    globalPortalSessionExpiresAt: globalPortalSessionExpiresAtCookie,
  });

  localStorage.setItem(localStorageLogoutError.logoutErrorsArray, JSON.stringify(parsedLocalStorageLogoutErrorsArray));
};

export const getParsedTimestampsArray = (stringifiedTimestampsArray: string | null) => {
  if (!stringifiedTimestampsArray) {
    return [];
  }

  let parsedArray: string[] = [];
  try {
    parsedArray = JSON.parse(stringifiedTimestampsArray ? stringifiedTimestampsArray : '[]');
  } catch (error) {}
  return parsedArray;
};

export const allSessionsInitTimestampsSetter = (
  areOktaRefreshTimestampsSet: boolean,
  areSessionExtendTimestampsSet: boolean,
  customUserSessionTimeout: number,
) => {
  const oktaCreatedAt = Cookie.getOktaSessionCreatedAt();
  const globalPortalSessionExpiresAt = Cookie.getGlobalPortalSessionExpiresAt();

  if (!areSessionExtendTimestampsSet && globalPortalSessionExpiresAt) {
    let sessionExtendTimestampsArray: string[] = [];

    // 1,2 and 4 represent the exhaustive list of available values for custom session timeout
    if (customUserSessionTimeout === customUserSessionTimeoutEnum.oneHour) {
      const firstRefresh = Number(globalPortalSessionExpiresAt) - 3 * HOUR_MILISEC;
      const secondRefresh = Number(globalPortalSessionExpiresAt) - 2 * HOUR_MILISEC;
      const thirdRefresh = Number(globalPortalSessionExpiresAt) - 1 * HOUR_MILISEC;
      sessionExtendTimestampsArray = [String(firstRefresh), String(secondRefresh), String(thirdRefresh)];
    }
    if (customUserSessionTimeout === customUserSessionTimeoutEnum.twoHours) {
      const firstRefresh = Number(globalPortalSessionExpiresAt) - 2 * HOUR_MILISEC;
      sessionExtendTimestampsArray = [String(firstRefresh)];
    }
    if (customUserSessionTimeout === customUserSessionTimeoutEnum.fourHours) {
      sessionExtendTimestampsArray = [];
    }
    localStorage.setItem('sessionExtendTimestampsArray', JSON.stringify(sessionExtendTimestampsArray));
  } else {
    localStorageLogoutErrorsArrayBuilder('ivalid globalPortalSessionExpiresAt in allSessionsInitTimestampsSetter');
  }

  if (!areOktaRefreshTimestampsSet && oktaCreatedAt) {
    const oktaRefreshTimestampsArray: string[] = [];

    if (customUserSessionTimeout === customUserSessionTimeoutEnum.oneHour) {
      // if customUserSessionTimeout is 1h, then we can tie up manual portal session refresh and okta refresh, never falling short of 2h okta idle time rule

      localStorage.setItem('oktaRefreshTimestampsArray', JSON.stringify(oktaRefreshTimestampsArray));
      return;
    }

    if (customUserSessionTimeout === customUserSessionTimeoutEnum.twoHours) {
      // if customUserSessionTimeout is 2h, then we can tie up init 2h portal refresh with okta 2h idle time refresh,
      // but having in mind user can extend portal session from 1:50:00 to 1:59:59, there is a chance that 2nd okta session will expire earlier then 2nd portal session
      // example: portal session is extended at 12:55 and portal will live till 15:00 (as extending portal session we take session end-time, which is 13:00 + 2h),
      // but okta will expire at 14:55, as each api call extends okta session for 2h from api call time
      // thus we need at least one successful okta refresh after pre 2h portal session extend: [2:00, 2:25, 2:50, 3:15, 3:40]
      const twoHoursFromCreationTimestamp = Number(oktaCreatedAt) + 2 * HOUR_MILISEC;

      for (let i = 0; i <= 4; i++) {
        oktaRefreshTimestampsArray.push(String(twoHoursFromCreationTimestamp + TWENTY_FIVE_MINS_IN_MS * i));
      }

      localStorage.setItem('oktaRefreshTimestampsArray', JSON.stringify(oktaRefreshTimestampsArray));
      return;
    }

    // if customUserSessionTimeout is 4h, then there will be no manual sessions refresh and we need to have logic to auto refresh session, abiding 2h idle time rule
    // 25m intervals would allow us to get up to 3 errors in a row (in a sleep mode or so) and still respect 2h idle time requirement,
    // therefore we initially populate our timestamps array with 9 25m intervals (0:25, 0:50, 1:15, 1:40, 2:05, 2:30, 2:55, 3:20, 3:45)
    // besides that we would try to refresh session 1m prior to init session lifetime limit (2h since created)
    for (let i = 0; i < 9; i++) {
      oktaRefreshTimestampsArray.push(String(Number(oktaCreatedAt) + TWENTY_FIVE_MINS_IN_MS * (1 + i)));
    }

    oktaRefreshTimestampsArray.push(String(Number(oktaCreatedAt) + ONE_HOUR_FIFTY_NINE_MINS_IN_MS));
    oktaRefreshTimestampsArray.sort((a, b) => Number(a) - Number(b));

    localStorage.setItem('oktaRefreshTimestampsArray', JSON.stringify(oktaRefreshTimestampsArray));
  } else {
    localStorageLogoutErrorsArrayBuilder('ivalid oktaCreatedAt in allSessionsInitTimestampsSetter');
  }
};

export const userSessionCookiesHandler = (isRefresh?: boolean) => {
  const stringifiedSessionExtendTimestamps = localStorage.getItem('sessionExtendTimestampsArray');
  const parsedArray = getParsedTimestampsArray(stringifiedSessionExtendTimestamps);

  if (isRefresh) {
    parsedArray.shift();
  }

  const globalPortalSessionExpiresAt = Cookie.getGlobalPortalSessionExpiresAt();

  const closestRefreshTimestamp = parsedArray[0] ? parsedArray[0] : globalPortalSessionExpiresAt!;
  Cookie.setGatewaySessionExpiresAt(closestRefreshTimestamp);
  localStorage.setItem('sessionExtendTimestampsArray', JSON.stringify(parsedArray));
};

export const convertSecondsToText = (seconds: number | null): string => {
  if (typeof seconds !== 'number') return '';
  const minutes = Math.floor(seconds / 60);
  const secondsLeft = seconds % 60;
  return `${minutes} minutes ${secondsLeft} seconds`;
};

export const getSessionExpireCountDownTime = (): number => {
  const gatewaySessionExpiresAt = Cookie.getGatewaySessionExpiresAt();

  if (!gatewaySessionExpiresAt) return 0;

  const timeNow = Date.now();
  const gatewaySessionDiffInMilliseconds = Number(gatewaySessionExpiresAt) - timeNow;
  return Math.max(0, Math.floor(gatewaySessionDiffInMilliseconds / 1000));
};
