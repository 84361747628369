import React from 'react';
import { IconButton, Card, Modal, Theme, SxProps } from '@mui/material';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';

const ModalContainerStyles: Record<string, SxProps<Theme> | undefined> = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: (theme) => ({
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    maxHeight: '800px',
    overflow: 'auto',
  }),
  close: {
    right: '3px',
    top: '3px',
    position: 'absolute',
    padding: '5px',
    zIndex: 999,
    '.close-icon': {
      width: '20px',
      height: '20px',
    },
  },
};

interface ModalContainerProps {
  isOpen: boolean;
  closeModal: (e?: any) => void;
  children: any;
  showCloseIcon: boolean;
  onClick?: (e?: any) => void;
  onKeyDown?: (e?: any) => void;
}

const ModalContainer = ({ isOpen, closeModal, children, showCloseIcon, onClick, onKeyDown }: ModalContainerProps) => (
  <Modal open={isOpen} onClose={closeModal} sx={ModalContainerStyles.modal} onClick={onClick} onKeyDown={onKeyDown}>
    <Card sx={ModalContainerStyles.root}>
      {showCloseIcon && (
        <IconButton sx={ModalContainerStyles.close} onClick={closeModal}>
          <CloseIcon className="close-icon" />
        </IconButton>
      )}
      {children}
    </Card>
  </Modal>
);

ModalContainer.defaultProps = {
  showCloseIcon: true,
};

export default ModalContainer;
