export const StateToken = 'stateToken';
export const AuthorizationCode = 'AuthorizationCode';
export const OktaTokenExpiresAt = 'oktaTokenExpiresAt';
export const Status = 'status';
export const FactorId = 'factorId';
export const SessionToken = 'sessionToken';
export const AccessToken = 'accessToken';
export const TempMessage = 'TempMessage';
export const RoleIDs = 'RoleIDs';
export const Pass = 'Pass';
export const DeviceToken = 'deviceToken';
export const UserId = 'userId';
export const RememberMe = 'rememberMe';
export const Email = 'email';
export const OktaToken = 'oktaToken';
export const RefreshToken = 'refreshToken';
export const UniqueSessionId = 'uniqueSessionId';
export const OktaSessionCreatedAt = 'oktaSessionCreatedAt';
export const OktaSessionExpiresAt = 'oktaSessionExpiresAt';
export const GatewaySessionExpiresAt = 'gatewaySessionExpiresAt';
export const GlobalPortalSessionExpiresAt = 'globalPortalSessionExpiresAt';
export const UserTimeoutLength = 'userTimeoutLength';
export const MessageID = 'MessageID';
export const BoxAccessToken = 'boxAccessToken';
export const BoxRefreshToken = 'boxRefreshToken';
export const BoxAccessTokenExpirationTime = 'boxAccessTokenExpirationTime';
export const OktaRememberUserLogin = 'ln';
