import { navigate } from 'gatsby';
import _ from 'lodash';
import { useState, useLayoutEffect } from 'react';

// constants.ts
import { Types, VerificationTypes } from '../constants';

// libraries
import { validateEmail, validatePassword } from './validation';

// service
import { AuthenticationAPI, Cookie } from '../services';
import { ICommonLink } from '../constants/CommonLinksConstants';

const getCodeFromRedirectUri = (url: string) => {
  const urlArray = _.split(url, 'code=');
  if (urlArray.length >= 2) {
    const subUrlArray = _.split(urlArray[1], '&state=');
    if (subUrlArray.length >= 2) {
      Cookie.setAuthorizationCode(subUrlArray[0]);
      return subUrlArray[0];
    }
  }
  return null;
};

const navigateToLogin = async (showLoader = true) => {
  await AuthenticationAPI.logout(showLoader);
};

// Login Flow functions
// - onMfaEnrollRequired
// - onMfaVerification
// - onLoginSetUp

const onMfaEnrollRequired = () => {
  navigate('/auth/phone-number');
};

const onMfaVerification = async (message: string) => {
  const sendMFARes = await AuthenticationAPI.sendMFA();

  if (sendMFARes.success) {
    navigate('/auth/verification', {
      state: {
        message,
        type: VerificationTypes.auth,
      },
    });
  } else {
    alert('Failure to send MFA code');
  }
};

const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return [match[2], '-', match[3], '-', match[4]].join('');
  }
  return null;
};

const getFirstCharOfEachWord = (str: string) => {
  const acronym = str.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '');
  return acronym;
};

const getAbbreviationOfName = (str: string) => {
  const plainStr = getPlainText(str);
  const acronym = plainStr.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '');
  return `${acronym[0] || ''}${acronym[1]}`;
};

const stripHtmlTagsFromString = (str: string) => {
  const div = document.createElement('div');
  div.innerHTML = str;
  const plainText = div.textContent || div.innerText || '';
  return plainText;
};

const getPlainText = (str: string) => {
  const letters = str.replace(/[^a-zA-Z ]/g, '');
  return letters;
};

const validURL = (str: string) => {
  const res = str.match(
    /http(s)?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  );
  if (res == null) return false;
  else return true;
};

const getHighWeightsForCategory = (categories: ICommonLink[]) => {
  let weight = 0;
  for (const category of categories) {
    if (weight < (category.weight || 0)) {
      weight = category.weight || 0;
    }
  }
  return weight;
};

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

const removeCharacter = (str: string, index: number) => {
  const part1 = str.substring(0, index);
  const part2 = str.substring(index + 1, str.length);
  return part1 + part2;
};

export const getLocationPathName = (locationPathname: string) => {
  let url = locationPathname;
  if (url[1] === '/') url = removeCharacter(url, 1);
  if (url[-1] === '/') url = removeCharacter(url, -1);
  return url;
};

const isCurrentMenuItemSelected = (item: Types.MenuItem, path: string) => {
  const isPathNotEmpty = Boolean(path);
  const isLinkToNotEmpty = Boolean(item?.link?.to);
  const isPathEqualsLinkTo = path === item?.link?.to;

  return isPathNotEmpty && isLinkToNotEmpty && isPathEqualsLinkTo;
};

export const sortByWeightLayout = (layouts?: Types.CategoryType[]) => {
  return _.sortBy(layouts, (currentLayout) => currentLayout.weight).map((currentKey) => currentKey.title);
};

const urlNameBuilder = (name: string) => {
  return name.toLowerCase().split(' ').join('-');
};

const urlNameRestorer = (urlName: string) => {
  return urlName.replace(/-/g, ' ').toUpperCase();
};

const navigateToSavedFolder = () => {
  const localStorageSubFolder = localStorage.getItem('sharefile-subfolder');
  const isDocumentLibraryPage = window.location.pathname.split('/')[1] === 'documents';
  if (localStorageSubFolder) {
    localStorage.removeItem('sharefile-subfolder');
    navigate(`/documents/${localStorageSubFolder}`);
  } else if (isDocumentLibraryPage) {
    navigate('/documents');
  }
};

const saveFolderToLocalStorage = (folderId: string) => {
  if (folderId && folderId.length > 0) {
    localStorage.setItem('sharefile-subfolder', folderId);
  }
};

const clearCurrentTimeout = (inter: number | null): void => {
  if (inter) {
    clearTimeout(inter);
    inter = null;
  }
};

const spliceArrayIntoChunks = <T>(arr: T[], chunkSize: number): T[][] => {
  const res = [];

  while (arr.length > 0) {
    const chunk = arr.splice(0, chunkSize);
    res.push(chunk);
  }

  return res;
};

const sleep = async (ms: number) => {
  await new Promise((resolve) => setTimeout(resolve, ms));
};

const navigateToDashboard = () => {
  navigate('/dashboard');
};

export * from './filterSupportedMFA';

export {
  spliceArrayIntoChunks,
  validateEmail,
  validatePassword,
  navigateToLogin,
  getCodeFromRedirectUri,
  onMfaEnrollRequired,
  onMfaVerification,
  formatPhoneNumber,
  getFirstCharOfEachWord,
  getAbbreviationOfName,
  stripHtmlTagsFromString,
  getPlainText,
  validURL,
  getHighWeightsForCategory,
  useWindowSize,
  isCurrentMenuItemSelected,
  urlNameBuilder,
  urlNameRestorer,
  navigateToSavedFolder,
  saveFolderToLocalStorage,
  clearCurrentTimeout,
  sleep,
  navigateToDashboard,
};
