import axios from 'axios';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';

import Cookie from '../Cookie';
import { BoxFolderStatus, BoxItem, BoxZipFile } from '../../constants/BoxConstants';
import { getFailedBoxApiCallResponse } from '../utils';
import { buildQueryParams } from '../../utils/query-utils';

export const getFolder = async (
  folderId: string,
  paginationModel: GridPaginationModel,
  sortModel: GridSortModel,
): Promise<{ success: boolean; data?: BoxItem; isEmailConfirmNeeded?: boolean; error?: string }> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const offset = paginationModel.page * paginationModel.pageSize;
    const direction = sortModel[0].sort?.toLocaleUpperCase();
    const sort = sortModel[0].field;
    const pageSize = paginationModel.pageSize;

    const queryParams = buildQueryParams([
      ['offset', offset],
      ['limit', pageSize],
      ['direction', direction],
      ['sort', sort],
    ]);

    const res = await axios.get(`/gateway/box/folder/${folderId}`, {
      params: queryParams,
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 200) {
      return { success: true, data: res.data.data };
    }
  } catch (e) {
    return getFailedBoxApiCallResponse(e);
  }
  return { success: false };
};

export const getFolderStatus = async (
  folderId: string,
): Promise<{ success: boolean; data?: BoxFolderStatus; error?: string }> => {
  try {
    const accessToken = Cookie.getAccessToken();

    const res = await axios.get(`/gateway/box/folder/${folderId}/collaboration_status`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 200) {
      return { success: true, data: res.data.data };
    }
  } catch (e) {
    return getFailedBoxApiCallResponse(e);
  }
  return { success: false };
};

export const getFile = async (
  fileId: string,
): Promise<{ success: boolean; data?: BoxItem; isEmailConfirmNeeded?: boolean; error?: string }> => {
  try {
    const accessToken = Cookie.getAccessToken();

    const res = await axios.get(`/gateway/box/files/${fileId}`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return { success: true, data: res.data.data };
    }
  } catch (e) {
    return getFailedBoxApiCallResponse(e);
  }
  return { success: false };
};

export const getDownloadFileUrl = async (
  fileId: string,
): Promise<{ success: boolean; data?: string; error?: string }> => {
  try {
    const accessToken = Cookie.getAccessToken();

    const res = await axios.get(`/gateway/box/files/${fileId}/content?type=url`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return { success: true, data: res.data.data };
    }
  } catch (e) {
    return getFailedBoxApiCallResponse(e);
  }
  return { success: false };
};

export const getDownloadZipFileUrl = async (
  data: BoxZipFile,
): Promise<{ success: boolean; data?: { download_url?: string } }> => {
  try {
    const accessToken = Cookie.getAccessToken();

    const res = await axios.post('/gateway/box/zip/content', data, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return { success: true, data: res.data.data };
    }
  } catch (e) {
    return getFailedBoxApiCallResponse(e);
  }
  return { success: false };
};

export const uploadFile = async (
  file: File,
  parentFolderId: string,
): Promise<{ success: boolean; data?: BoxItem; error?: string }> => {
  try {
    const accessToken = Cookie.getAccessToken();

    const formData = new FormData();
    formData.append('file', file);
    formData.append('parentFolderId', parentFolderId);
    const res = await axios.post('/gateway/box/files/content', formData, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return { success: true, data: res.data.data };
    } else {
      return { success: false, data: res.data.message };
    }
  } catch (e) {
    return getFailedBoxApiCallResponse(e);
  }
};

export const getItemsBySearch = async (searchText: string): Promise<{ success: boolean; data?: BoxItem[] }> => {
  try {
    const accessToken = Cookie.getAccessToken();

    const res = await axios.get(`/gateway/box/search?name=${searchText}`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return { success: true, data: res.data.data.entries };
    }
  } catch (e) {
    return getFailedBoxApiCallResponse(e);
  }
  return { success: false };
};

export const getFilePreview = async (
  fileId: string,
): Promise<{ success: boolean; data?: string; isEmailConfirmNeeded?: boolean }> => {
  try {
    const accessToken = Cookie.getAccessToken();

    const res = await axios.get(`/gateway/box/files/${fileId}/preview`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return { success: true, data: res.data.data };
    }
  } catch (e) {
    return getFailedBoxApiCallResponse(e);
  }
  return { success: false };
};
